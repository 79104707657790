
// TODO 多选在分页时存在问题
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { getDispatch, DispatchDto } from '@/api/dispatch'
import { addChangeCompany } from '@/api/worker'
import { dateFormat } from '@/utils/util'

export default defineComponent({
    props: ['row'],
    components: {
        'alert-box': alertBox
    },
    async created () {
        const { data: dispatches } = await getDispatch({
            policyId: this.row.policyId,
            page: 0,
            pageSize: 100
        })
        this.dispatches = dispatches
        this.tommorow = dateFormat('YYYY-mm-dd', new Date(new Date().setDate(new Date().getDate() + 1)))
    },
    data () {
        return {
            company_id: 0,
            company_name: '',
            occupation_id: 0,
            occupation_name: '',
            dispatches: [] as DispatchDto[],
            occupationAutoData: [] as {
              occupation_id: number
              name: string
              level: number
            }[],
            tommorow: ''
        }
    },

    methods: {
        // 被派遣单位选择
        selectCompany (item: {
          value: string
          id: number,
          occupation: { occupation_id: number; name: string; level: number }[]
        }) {
            this.company_id = item.id
            this.occupation_name = ''
            this.occupation_id = 0
            this.occupationAutoData = item.occupation
        },
        autoCompany (
            queryString: string,
            cb: (val: {
              value: string
              id: number
            }[]) => void
        ) {
            cb(
                this.dispatches
                    .filter(item => item.name.indexOf(queryString) !== -1)
                    .map(item => {
                        return {
                            value: item.name,
                            id: item.company_id,
                            occupation: item.occupation
                        }
                    })
            )
        },
        selectOccupation (value: number) {
            this.occupation_id = value
        },
        close () {
            this.$emit('close')
        },
        async confirm () {
            if (!this.company_id) {
                this.$message.error('请选择新的被派遣单位')
                return
            }
            if (!this.occupation_id) {
                this.$message.error('请选择新的工种')
                return
            }
            await this.$util.confirm('员工换厂', '确认要执行此操作吗？')
            await addChangeCompany({
                policyPersonId: this.row.policy_person_id,
                companyId: this.company_id,
                occupationId: this.occupation_id
            })
            this.$message.success('操作成功')
            this.$emit('confirm')
        }
    }
})
