import request from './request'
import { AxiosRequestConfig } from 'axios'
import { QueryPageDto, WorkerDto } from './dto'
import { InvoiceDto } from './invoice'

// 获取用户基本信息
interface QueryWorkersDTO extends QueryPageDto {
    onlyActive: string
    policyId?: number
    company?: string
}
export function getWorkers (
    params: QueryWorkersDTO
): Promise<{ data: WorkerDto[]; total: number }> {
    return request.get('/employee/list', {
        params
    })
}

interface GetWorkersDetailDto {
    employee_id: number
    idcard: string
    is_active: number
    name: string
    records: InvoiceDto[]
}
// 弹出列表 data: WorkerDto[], total: number
export function getWorkersDetail (
    params: {
        employeeId: string
    },
    config = {} as AxiosRequestConfig
): Promise<GetWorkersDetailDto> {
    config.params = params
    return request.get('/employee/detail', config as AxiosRequestConfig)
}

interface addChangeCompanyReqDto {
  policyPersonId: number
  companyId: number
  occupationId: number
}
export function addChangeCompany (data: addChangeCompanyReqDto): Promise<void> {
    return request.post('/order/changeCompany', data)
}
