
import { defineComponent } from 'vue'
import { InvoiceDto } from '@/api/invoice'
import { getWorkers, getWorkersDetail } from '@/api/worker'
import { WorkerDto } from '@/api/dto'
import alertBox from '@/components/alert-box.vue'
import { DispatchDto, getDispatch } from '@/api/dispatch'
import ChangeCompany from '@/views/index/components/change-company.vue'

export default defineComponent({
    name: 'Workers',
    components: {
        ChangeCompany,
        alertBox
    },
    data () {
        return {
            searchValue: '',
            searchText: '',
            companyValue: '',
            companyText: '',
            pageNum: 1,
            pageSize: 10,
            onlyActive: '0',
            total: 0,
            isAlert: false,
            isChangeCompanyAlert: false,
            tableData: [] as WorkerDto[],
            alertBox: {
                name: '',
                idcard: '',
                type: '' as string | number,
                table: [] as InvoiceDto[]
            },
            editWorker: {} as WorkerDto
        }
    },
    created () {
        this.handleCurrentChange()
    },
    methods: {
    // 被派遣单位自动完成
        async patchAuto (
            queryString: string,
            cb: (
        val: {
          value: string
          id: number
        }[]
      ) => void
        ): Promise<void> {
            const result = await getDispatch(
                {
                    page: 1,
                    search: queryString,
                    pageSize: 10
                },
                {
                    isLoading: false
                }
            )
            cb(
                result.data
                    .map((item: DispatchDto) => {
                        return {
                            value: item.name,
                            id: item.company_id
                        }
                    })
                    .filter((item: { value: string }) =>
                        item.value.includes(queryString)
                    )
            )
        },
        // 被派遣单位选择
        selectPatch (item: { value: string; id: number }) {
            this.companyText = item.value
        },
        async handleCurrentChange () {
            const result = await getWorkers({
                page: this.pageNum,
                pageSize: this.pageSize,
                onlyActive: this.onlyActive,
                search: this.searchValue,
                company: this.companyValue
            })
            this.tableData = result.data
            this.total = result.total
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.companyValue = this.companyText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        // 投保记录
        isRecord (id: string) {
            this.isAlert = !this.isAlert
            getWorkersDetail({
                employeeId: id
            }).then(res => {
                this.alertBox.name = res.name
                this.alertBox.idcard = res.idcard
                this.alertBox.type = res.is_active
                this.alertBox.table = res.records
                console.log(this.alertBox.table, 'alertBox.table')
            })
        },
        // 换厂
        changeCompany (row: WorkerDto) {
            this.isChangeCompanyAlert = true
            this.editWorker = row
        },
        close () {
            this.isAlert = !this.isAlert
            this.alertBox.name = ''
            this.alertBox.idcard = ''
            this.alertBox.type = ''
            this.alertBox.table = []
        },
        changeCompanyClose () {
            this.isChangeCompanyAlert = !this.isChangeCompanyAlert
        },
        changeCompanyConfirm () {
            this.isChangeCompanyAlert = false
            this.handleCurrentChange()
        }
    }
})
